* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.NavBar_Container {
  font-size: calc(16px + 1vmin) !important;
  position: absolute;
  width: 100%;
  /* margin-bottom:100px; */
}

.NavBar_Container a
{
    text-decoration: none !important;
    color: #fdf9f9 !important;
}

.NavBar_Container a:hover
{
    color: #7c7b7b !important;
}

.navbar-header
{
    font-size: calc(20px + 1vmin) !important;
}

.navbar {
  background-color: #363d7c !important;
  color: #fdf9f9 !important;
}

.nav-item button
{
    width: 100%;
    color: #fdf9f9 !important;
}

.dropdown-button
{
    color: #fdf9f9 !important;
    background-color: #fdf9f9 !important;
}

.nav-dropdown
{
    /* background-color: #fdf9f9; */
    border: solid #D2D2D2 0.5px;
    margin-top: 10px;
    justify-content: center;
    text-align: center;
    border-radius: calc(10px + 1vmin);
}

.nav-dropdown a
{
    color: #7c7b7b;
    width: 100%;
    padding: calc(2px + 1vmin);
}

.nav-dropdown a:hover
{
    border-bottom: solid #363d7c !important;
    border-radius: calc(2px + 1vmin) !important;
}

.nav-profileImg
{
    width: calc(60px + 1vmin);
}

.profileImg
{
    display: none;
}

@media (min-width: 991px) {
    .nav-dropdown
    {
        border: none;
    }

    .profileImg
    {
        display: block;
        width: calc(60px + 1vmin);
    }
}