*
{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

html, body{
    background-color: #cfd6e0 !important;
}

.footercontain{
    width: 100%;
    background-color: #cfd6e0 !important;
}


.footer-text{
    color: rgb(13, 110, 253) !important;
}