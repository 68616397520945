* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

.basecontainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  scrollbar-width: thin;
  padding-bottom: 0;
  background-color: #cfd6e0 !important;
}

.signcard {
  height: auto;
  width: 400px;
  background-color: white;
  margin: auto;
  box-shadow: 2px 2px 5px rgb(151, 150, 150);
  border: solid z1px rgb(151, 150, 150);
  border-radius: calc(10px + 1vmin);
  padding: calc(20px + 1vmin);
  margin-bottom: 60px;
}

.header{
  margin: auto;
  margin-bottom: 30px;
  border-bottom: .5px solid black;
  width: 70%;
  padding: 10px;
  padding-bottom: 15px;

  
}

.formcontent form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

form input {
  padding: calc(5px + 1vmin);
  font-size: calc(16px + 1vmin);
  border: solid 1px rgb(117, 117, 117);
  border-radius: calc(10px + 1vmin);
  margin-bottom: 10px;
}

.submitForm input:hover {
  background-color: #424ca8;
}

.submitForm input {
  width: 100%;
  background-color: #363d7c;
  color: #fdf9f9;
  padding: calc(5px + 1vmin);
  font-size: calc(16px + 1vmin);
  border: solid 1px rgb(117, 117, 117);
  border-radius: calc(10px + 1vmin);
}

.GoogleSignin
{
  /* width: 100% !important; */
  margin-left: auto;
  margin-right: auto;
}