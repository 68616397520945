* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signUpContainer {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-inline: calc(15px + 1vmin);
  scrollbar-width: thin;
  padding-top: 100px;
  background-color: #cfd6e0 !important;
}

.subContainer {
  background-color: white;
  height: auto;
  display: flex;
  box-shadow: 2px 2px 5px rgb(151, 150, 150);
  border: solid z1px rgb(151, 150, 150);
  border-radius: calc(10px + 1vmin);
  padding: calc(20px + 1vmin);
  margin: auto;
  margin-bottom: 50px;
}

.signUpContent {
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  
}

.signUpContent h3 {
  font-size: calc(40px + 1vmin);
  color: #363d7c;
}

.signUpContent p {
  color: rgb(151, 150, 150);
}

.signUpContent form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.signUpContent input {
  padding: calc(5px + 1vmin);
  font-size: calc(16px + 1vmin);
  border: solid 1px rgb(117, 117, 117);
  border-radius: calc(10px + 1vmin);
}

.submitForm input {
  width: 100%;
  background-color: #363d7c;
  color: #fdf9f9;
  padding: calc(5px + 1vmin);
  font-size: calc(16px + 1vmin);
  border: solid 1px rgb(117, 117, 117);
  border-radius: calc(10px + 1vmin);
}

.signUpContent input:focus {
  border-color: #363d7c;
  outline: none;
}

.submitForm input:hover {
  background-color: #424ca8;
}

.validation {
  margin: 0;
  padding: 0;
}

.validation p {
  color: red;
  display: none;
}

/* .signUpContent button {
  padding: calc(5px + 1vmin);
  font-size: calc(15px + 1vmin);
  border: solid 1px rgb(117, 117, 117);
  border-radius: calc(10px + 1vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(15px + 1vmin);
  color: rgb(117, 117, 117);
}

.signUpContent button:hover {
  background-color: rgb(224, 222, 222);
}

.googleIcon {
  width: calc(25px + 1vmin);
} */

.GoogleSignin
{
  /* width: 100% !important; */
  margin-left: auto;
  margin-right: auto;
}

.signUpContent a {
  text-decoration: none;
  margin-block: 15px;
  color: #363d7c;
}

.signUpContent a:hover
{
  text-shadow: 0.5px 0.5px 10px #424ca8;
}

@media (min-width: 991px) {
  .signUpContainer {
    padding-inline: calc(400px + 1vmin);
    padding-bottom: 25px;
  }
}